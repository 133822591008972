/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        common: {
            init: function() {

                /**
                 *  Init WOW.js
                 *  @link https://github.com/matthieua/WOW
                 */
                var wow = new WOW(
                  {
                    boxClass:     'animate',      // animated element css class (default is wow)
                    animateClass: 'animated', // animation css class (default is animated)
                    offset:       0,          // distance to the element when triggering the animation (default is 0)
                  }
                );
                wow.init();

                var $body = $("body");


                if ( $body.hasClass('has-notification-bar') ) {
                    $headerHeight = $('.site-header').outerHeight();
                    $(".menus.mobile").css('top', $headerHeight + 'px');
                    $(".menus.mobile").css('height', 'calc(100% - ' + $headerHeight + ')');

                    $('.notification-bar--close').on('click', function() {
                        $('.notification-bar').slideUp('fast', function() {
                            $headerHeight = $('.site-header').outerHeight();
                            $(".menus.mobile").css('top', $headerHeight + 'px');
                            $(".menus.mobile").css('height', 'calc(100% - ' + $headerHeight + ')');
                        });

                        $siteInfo = $('.site-info').outerHeight();
                        $('main.main').css('margin-top', $siteInfo );

                    });
                }




                /**
                 *  Add shrink class to header on scroll
                 */
                var $threshold = 100,
                    $window = $(window);

                $window.scroll(function() {
                    $scroll_position = $window.scrollTop();
                    if ($scroll_position > $threshold) {
                        $body.addClass("shrink");
                    } else {
                        $body.removeClass("shrink");
                    }
                });

                $(".menu-trigger").on("click", function(e) {
                    e.preventDefault();
                    if ($body.hasClass("menu-open")) {
                        $body.removeClass("menu-open");
                    } else {
                        $body.addClass("menu-open");
                    }
                });

                /**
                 *  Mobile menu sub menu functionality
                 */

                  $('.menus.mobile .menu-item-has-children > a').on('click', function (e) {

                        e.preventDefault();

                        $(this).parent().toggleClass('active-sub');

                        var panel = $(this).next('.sub-menu');

                        if (panel[0].style.maxHeight) {
                          panel[0].style.maxHeight = null;
                        } else {
                          panel[0].style.maxHeight = panel[0].scrollHeight + "px";
                        } 

                  });

                /**
                 *  Accordion toggle functionality
                 */

                $(".accordion .accordion--title").on("click", function(e) {
                    $this = $(this);
                    $target = $this.next();
                    $parent = $this.parent();

                    $parent.toggleClass("active");
                    $target.slideToggle();

                    return false;
                });

                /**
                 *  scroll to section for btn
                 */
                $("a[href^='#']").click(function(e) {
                    e.preventDefault();
                    href = $(this).attr("href");
                    if (href.length > 1) {
                        $('body').removeClass('menu-open');
                        $("html, body").animate(
                            {
                                scrollTop: $(href).offset().top - 66
                            },
                            1000
                        );
                    }
                });

                /**
                 *  Add body class on scroll up
                 */

                    var position = $(window).scrollTop(); 

                    // should start at 0

                    $(window).scroll(function() {
                        var scroll = $(window).scrollTop();
                        if(scroll > position) {
                            $('body').removeClass('scroll-up');
                        } else {
                             $('body').addClass('scroll-up');
                        }
                        position = scroll;
                    });

                /**
                 * Expand textarea on focus or content entry
                 */

                $('.expand-on-focus').each(function() {

                    $inputContainer = $(this).find('.ginput_container');
                    
                    if ( $inputContainer.hasClass('ginput_container_textarea') ) {
                        $input = $(this).find('textarea');                                                
                    } else {
                        $input = $(this).find('input');
                    }

                    $input.on('change keyup input focusin', function() {
                        $(this).parent().parent().addClass('expanded');
                    });

                    $input.on('focusout', function() {
                        if ( $(this).val() !== '' ) {
                            $(this).parent().parent().addClass('expanded');
                        } else {
                            $(this).parent().parent().removeClass('expanded');
                        }
                    });

                 });

            }
        },
        /**
         *  Counter Block
         */
        counter_block: {
            init: function() {
               $('.counter--number span').counterUp();
            }
        },
        /**
         *  XXX Block
         */
        xxx_block: {
            init: function() {
               // ...
            }
        },
        
        
        
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function(i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
